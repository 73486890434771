import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
        <div className="top-line"></div>

        <h1 className="main-text">BEIJARD</h1>
        </div>
);
}

export default App;

